<template>
    <div v-if="page_loaded" class="plans_box">
        <div class="item_plan">
            <div class="plan_title">Basic Plan</div>
            <div class="d-flex justify-content-center">
                <ul class="list_p">
                    <li class="font_weight">0-100 edited pictures a month</li>
                    <template v-if="user.discount">
                        <li>Basic Retouching ${{((plans.BASIC.retouching.Simple / 100)
                            - ((plans.BASIC.retouching.Simple / 100) * (user.discount / 100))).toFixed(2)}}/ image</li>
                        <li>Intermediate Retouching ${{((plans.BASIC.retouching.Intermediate / 100) -
                        ((plans.BASIC.retouching.Intermediate / 100) * (user.discount / 100))).toFixed(2)}}/image</li>
                        <li>Detailed Retouching ${{((plans.BASIC.retouching.Advanced / 100) -
                            ((plans.BASIC.retouching.Advanced / 100) * (user.discount / 100))).toFixed(2)}}/image</li>
                    </template>
                    <template v-else>
                        <li>Basic Retouching ${{plans.BASIC.retouching.Simple / 100}}/ image</li>
                        <li>Intermediate Retouching ${{plans.BASIC.retouching.Intermediate / 100}}/image</li>
                        <li>Detailed Retouching ${{plans.BASIC.retouching.Advanced / 100}}/image</li>
                    </template>
                </ul>
            </div>
            <div v-if="user.discount" class="price_plan">${{((plans.BASIC.price / 100) -
                ((plans.BASIC.price / 100) * (user.discount / 100))).toFixed(2)}}/month</div>
            <div v-else class="price_plan">${{plans.BASIC.price / 100}}/month</div>
            <template v-if="getPlanName===MembershipPlanEnum.BASIC">
                <div class="btn_box">
                    <div class="btn_p1_0 cp">Current Plan</div>
                </div>
                <div v-if="getContinuationPlan" class="cancel_memb" @click="showModalCancelPlan(MembershipPlanEnum.BASIC)">Cancel Membership renewal
                </div>
                <div v-else class="cancel_memb">
                    Left: {{getDaysLeft(user_plan.finished_date)}} days
                </div>
            </template>
            <template v-else>
                <div class="btn_box pb_box">
                    <div v-if="getPlanName" class="btn_p1 cp" @click="showAddPlanModal(MembershipPlanEnum.BASIC)">Downgrade</div>
                    <div v-else class="btn_p1 cp" @click="showAddPlanModal(MembershipPlanEnum.BASIC)">Select</div>
                </div>
            </template>
        </div>
        <div class="item_plan">
            <div class="plan_title">Intermediate Plan</div>
            <div class="d-flex justify-content-center">
                <ul class="list_p">
                    <li class="font_weight">101-1000 edited pictures a month</li>
                    <template v-if="user.discount">
                        <li>Basic Retouching ${{((plans.INTERMEDIATE.retouching.Simple / 100) -
                            ((plans.INTERMEDIATE.retouching.Simple / 100) * (user.discount / 100))).toFixed(2)}}/ image</li>
                        <li>Intermediate Retouching ${{((plans.INTERMEDIATE.retouching.Intermediate / 100) -
                            ((plans.INTERMEDIATE.retouching.Intermediate / 100) * (user.discount / 100))).toFixed(2)}}/image</li>
                        <li>Detailed Retouching ${{((plans.INTERMEDIATE.retouching.Advanced / 100) -
                            ((plans.INTERMEDIATE.retouching.Advanced / 100) * (user.discount / 100))).toFixed(2)}}/image</li>
                    </template>
                    <template v-else>
                        <li>Basic Retouching ${{plans.INTERMEDIATE.retouching.Simple / 100}}/ image</li>
                        <li>Intermediate Retouching ${{plans.INTERMEDIATE.retouching.Intermediate / 100}}/image</li>
                        <li>Detailed Retouching ${{plans.INTERMEDIATE.retouching.Advanced / 100}}/image</li>
                    </template>
                </ul>
            </div>
            <div v-if="user.discount" class="price_plan">${{((plans.INTERMEDIATE.price / 100) -
                ((plans.INTERMEDIATE.price / 100) * (user.discount / 100))).toFixed(2)}}/month</div>
            <div v-else class="price_plan">${{plans.INTERMEDIATE.price / 100}}/month</div>
            <template v-if="getPlanName===MembershipPlanEnum.INTERMEDIATE">
                <div class="btn_box">
                    <div class="btn_p1_0 cp">Current Plan</div>
                </div>
                <div v-if="getContinuationPlan" class="cancel_memb" @click="showModalCancelPlan(MembershipPlanEnum.INTERMEDIATE)">
                    Cancel Membership renewal
                </div>
                <div v-else class="cancel_memb">
                    Left: {{getDaysLeft(user_plan.finished_date)}} days
                </div>
            </template>
            <template v-else>
                <div class="btn_box pb_box">
                    <div v-if="getPlanName && getPlanName==MembershipPlanEnum.BASIC" class="btn_p1 cp" @click="showAddPlanModal(MembershipPlanEnum.INTERMEDIATE)">Upgrade</div>
                    <div v-else-if="getPlanName && getPlanName==MembershipPlanEnum.EXPERT" class="btn_p1 cp" @click="showAddPlanModal(MembershipPlanEnum.INTERMEDIATE)">Downgrade</div>
                    <div v-else class="btn_p1 cp" @click="showAddPlanModal(MembershipPlanEnum.INTERMEDIATE)">Select</div>
                </div>
            </template>
        </div>
        <div class="item_plan">
            <div class="plan_title">Expert Plan</div>
            <div class="d-flex justify-content-center">
                <ul class="list_p">
                    <li class="font_weight">1001-and up edited pictures a month</li>
                    <template v-if="user.discount">
                        <li>Basic Retouching ${{((plans.EXPERT.retouching.Simple / 100) -
                            ((plans.EXPERT.retouching.Simple / 100) * (user.discount / 100))).toFixed(2)}}/ image</li>
                        <li>Intermediate Retouching ${{((plans.EXPERT.retouching.Intermediate / 100) -
                            ((plans.EXPERT.retouching.Intermediate / 100) * (user.discount / 100))).toFixed(2)}}/image</li>
                        <li>Detailed Retouching ${{((plans.EXPERT.retouching.Advanced / 100) -
                            ((plans.EXPERT.retouching.Advanced / 100) * (user.discount / 100))).toFixed(2)}}/image</li>
                    </template>
                    <template v-else>
                        <li>Basic Retouching ${{plans.EXPERT.retouching.Simple / 100}}/ image</li>
                        <li>Intermediate Retouching ${{plans.EXPERT.retouching.Intermediate / 100}}/image</li>
                        <li>Detailed Retouching ${{plans.EXPERT.retouching.Advanced / 100}}/image</li>
                    </template>
                </ul>
            </div>
            <div v-if="user.discount" class="price_plan">${{((plans.EXPERT.price / 100) -
                ((plans.EXPERT.price / 100) * (user.discount / 100))).toFixed(2)}}/month</div>
            <div v-else class="price_plan">${{plans.EXPERT.price / 100}}/month</div>
            <template v-if="getPlanName===MembershipPlanEnum.EXPERT">
                <div class="btn_box">
                    <div class="btn_p1_0 cp">Current Plan</div>
                </div>
                <div v-if="getContinuationPlan" class="cancel_memb" @click="showModalCancelPlan(MembershipPlanEnum.EXPERT)">Cancel Membership renewal
                </div>
                <div v-else class="cancel_memb">
                    Left: {{getDaysLeft(user_plan.finished_date)}} days
                </div>
            </template>
            <template v-else>
                <div class="btn_box pb_box">
                    <div v-if="getPlanName" class="btn_p1 cp" @click="showAddPlanModal(MembershipPlanEnum.EXPERT)">Upgrade</div>
                    <div v-else class="btn_p1 cp" @click="showAddPlanModal(MembershipPlanEnum.EXPERT)">Select</div>
                </div>
            </template>
        </div>
        <div class="item_plan mb-70 d-flex flex-column justify-content-between">
            <template>
                <div class="plan_title">Enterprise Plan</div>
                <div class="d-flex justify-content-center">
                    <ul class="list_p">
                        <li class="font_weight">Unlimited edited pictures a month</li>
                        <li>Please message us for special pricing</li>
                    </ul>
                </div>
            </template>
            <template>
                <div class="btn_box pb_box">
                    <a href="mailto:info@strobeart.com" class="btn_p1 cp without-underline">Contact Us</a>
                </div>
            </template>
        </div>
        <div class="modal" id="cancelPlanModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Are you sure you want to cancel your subscription?</h5>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="cancelPlan">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="addPlanModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Do you want to continue with the {{ selected_new_plan }} plan?</h5>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="addPlan">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="nonCardModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">You haven't any card</h5>
                    </div>
                    <div class="modal-body text-center">
                        Click <router-link :to="{name: 'Settings', query: { tab: 'billing' }}" class="like_link" target="_blank" @click.native="addCard">
                            here
                        </router-link> to add new card
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {Modal} from "bootstrap";
import {errorMessage} from "../services/messages";
import MembershipPlanEnum from "../enums/MembershipPlanEnum";
import moment from 'moment-timezone'

export default {
    name: "PaymentPlan",
    components: {
        MembershipPlanEnum
    },
    data() {
        return {
            page_loaded: false,
            user: {},
            cancelPlanModal: null,
            addPlanModal: null,
            nonCardModal: null,
            MembershipPlanEnum: MembershipPlanEnum,
            selected_new_plan: null,
            user_plan: {},
            plans: [],
        };
    },
    destroyed() {
        if (this.cancelPlanModal) {
            this.cancelPlanModal.dispose();
        }
        if (this.addPlanModal) {
            this.addPlanModal.dispose();
        }
        if (this.nonCardModal) {
            this.nonCardModal.dispose();
        }
    },
    async mounted() {
        await this.getPlans();
        this.addPlanModal = new Modal(document.getElementById('addPlanModal'));
        this.nonCardModal = new Modal(document.getElementById('nonCardModal'));
        this.cancelPlanModal = new Modal(document.getElementById('cancelPlanModal'));
        await this.updateUser();
        this.getPlan();
    },
    computed: {
        ...mapGetters([
            'getUser',
            'getStripeKey'
        ]),
        getPlanName() {
            return this.user_plan?.plan || null;
        },
        getContinuationPlan() {
            return this.user_plan?.continuation_plan || false;
        }
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async getPlans() {
            try {
                this.showLoader();
                const response = await this.$http.get(`${this.$http.apiUrl()}get-plans-prices`);
                if (response.data.data) {
                    this.plans = response.data.data;
                    this.page_loaded = true;
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async updateUser() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/setting`);
                this.user = response?.data?.data?.user || {};
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        getPlan() {
            this.user_plan = this.user?.plan || {};
        },
        showAddPlanModal(value) {
            this.selected_new_plan = value;
            this.addPlanModal.show();
        },
        showModalCancelPlan(value) {
            this.cancelPlanModal.show();
        },
        async cancelPlan() {
            this.showLoader();
            try {
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/cancel-membership-plan`);
                this.cancelPlanModal.hide();
                this.user_plan.continuation_plan = 0;
                errorMessage('Success!');
            } catch (e) {
                if (e?.response?.data?.error?.message) {
                    errorMessage(e.response.data.error.message);
                } else {
                    errorMessage('ERROR');
                }
            }
            this.hideLoader();
        },
        async addPlan() {
            this.showLoader();
            try {
                const plan = await this.$http.postAuth(`${this.$http.apiUrl()}profile/add-membership-plan`, {
                    plan: this.selected_new_plan,
                    timezone: moment.tz.guess(true),
                });
                this.user_plan = plan?.data?.data || {};
                this.addPlanModal.hide();
            } catch (e) {
                if (e?.response?.data?.error?.message) {
                    if (e.response.data.error.message == 'Non card') {
                        this.addPlanModal.hide();
                        this.nonCardModal.show();
                    } else {
                        errorMessage(e.response.data.error.message);
                    }
                } else {
                    errorMessage('ERROR');
                }
            }
            this.hideLoader();
        },
        addCard() {
            this.nonCardModal.hide();
        },
        getDaysLeft(date) {
            return moment(date).diff(moment(), 'days');
        },
    }
}
</script>

<style lang="scss" scoped>
.mb-70 {
    @media only screen and (max-width: 992px) {
        margin-bottom: 70px;
    }
}
.like_link {
    text-decoration: underline;
    color: black;
    font-weight: 700;
    cursor: pointer;
}
.plans_box {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
}
.item_plan {
    flex: 1 1 15em;
    min-width: 229px;
    background: white;
    box-sizing: border-box;
    box-shadow: 3px 3px 8px rgba(216, 195, 175, 0.4);
}
.plan_title {
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 44px;
    color: #494949;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 17px 0;
}
.list_p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: black;
    margin-bottom: 0;
}
.list_p li {
    padding: 1px 11px 10px 11px;
}
.price_plan {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: #494949;
    display: flex;
    justify-content: center;
}
.font_weight {
    font-weight: 700;
}
.pb_box {
    padding-bottom: 50px;
}
.btn_box {
    padding-top: 17px;
    display: flex;
    justify-content: center;
}
.cancel_memb {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #AD967F;
    text-align: center;
    padding-top: 9px;
    cursor: pointer;
    padding-bottom: 20px;
}
.modal-title {
    width: 100%;
    text-align: center;
}
.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    gap: 35px;
}
.modal-header {
    border-bottom: 0px solid #dee2e6;
}
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}

@media only screen and (max-width: 992px) {
    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        gap: 15px;
    }
}
.without-underline {
    text-decoration: none;
}
</style>
