<template>
    <div>
        <MainLayout :is-show-header="true">
            <div class="page_profile_setting">
                <div class="col-3 menu_setting">
                    <div class="menu_title cp" @click="selectMenu(1)" :class="{active_menu:selected_menu===1}">
                        <template v-if="isMobile">Info</template>
                    </div>
                    <div v-if="is_type_user===business" class="menu_title cp" @click="selectMenu(2)" :class="{active_menu:selected_menu===2}">
                        Membership
                    </div>
                    <div class="menu_title cp" @click="selectMenu(3)" :class="{active_menu:selected_menu===3}">
                        <template v-if="!isMobile">Billing & Payments</template>
                        <template v-else>Billing</template>
                    </div>
                    <div v-if="is_type_user===editor" class="menu_title cp" @click="selectMenu(4)" :class="{active_menu:selected_menu===4}">
                        <template v-if="isMobile">Reviews</template>
                    </div>
                </div>
                <div class="col-9 setting_box">
                    <div class="box_selected_m" v-if="selected_menu===1">
                        <div class="mobile_menu_1 pb_65" v-if="isMobile">
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="box_avatar">
                                        <img v-if="avatar" class="avatar" :src=avatar alt="">
                                        <img v-else class="avatar" src='@/assets/images/sbcf-default-avatar.png' alt="">
                                        <img @click="editAvatar" class="icon_edit" src='@/assets/icons/editor_dark.svg' alt="">
                                        <input type="file" ref="avatar_file" @change="updateAvatar" hidden>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-5">
                                <div class="col-6 offset-3 with_underline">
                                    <div class="user_name cp text-center" @click="openEditModal('userName')">{{ user.first_name }} {{ user.last_name }}</div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12 with_underline pb-1">
                                    <div class="info_user" @click="openEditModal('userBusiness')">
                                        <img class="me-1" v-if="is_type_user===business" src='@/assets/icons/business-center.svg' alt="">
                                        <img class="me-1" v-else src='@/assets/icons/bi_person-fill.svg' alt="">
                                        <div class="text2">{{ user.business_name ? user.business_name : 'Enter your Company Name' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12 with_underline pb-1">
                                    <div class="info2">
                                        <div class="info_user" @click="openEditModal('userLocation')" >
                                            <img class="me-1" src='@/assets/icons/ep_location.svg' alt="">
                                            <div class="text2">{{ user.location ? user.location : 'Enter your City Name' }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 with_border" @click="openEditModal('userBio')">
                                    <div  class="bio_text">
                                        {{user.bio ? user.bio : `Here you can add information about yourself. Try to choose the most interesting points of your biography. Good luck!`}}
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 mb-4">
                                <div class="col-12 with_underline pb-1">
                                     <div class="email__block info_user cp  pb-2" @click="openEditModal('userEmail')">
                                         <div class="text2">
                                             {{ user.email }}
                                         </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 mb-4">
                                <div class="col-12 with_underline pb-1">
                                    <div class="phone__block info_user cp  pb-2" @click="openEditModal('userPhone')">
                                        <div class="text2">
                                            {{ user.phone }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12 with_underline pb-2">
                                    <div class="phone__block cp" @click="openEditModal('userPassword')">
                                        <div class="text2">
                                            <button class="btn-c3">Change Password</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-12 with_underline pb-2">
                                    <div class="phone__block cp" @click="openEditModal('userCoupon')">
                                        <div class="text2">
                                            <button class="btn-c3">Apply Coupon</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12 mt-3 d-flex flex-column justify-content-around">
                                <span v-if="is_type_user===business&&is_instagram&&isMobile" @click="unlinkInstagramModalO()" class="unlink_instagram mob_v mb-3 w-75 ms-auto me-auto">
                                    Unlink Instagram
                                </span>
                                    <span v-if=" is_type_user===business&&is_shopify&&isMobile" @click="unlinkShopifyModalOpen()" class="unlink_instagram mob_v mb-3 w-75 ms-auto me-auto">
                                    Unlink Shopify
                                </span>
                                    <span v-if=" is_type_user===business&&is_amazon&&isMobile" @click="unlinkAmazonModalOpen()" class="unlink_instagram mob_v mb-3 w-75 ms-auto me-auto">
                                    Unlink Amazon
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box_selected_m" v-if="selected_menu===2 && is_type_user===business">
                        <PaymentPlan/>
                    </div>
                    <div class="box_selected_m" v-if="selected_menu===3">
                        <BuildingPayment v-if="is_type_user===business" :payment_history="payment_history" :user_card="user_card" :user="user"/>
                        <BuildingPaymentEditor v-else :user="user" :payment_history="payment_history" :user_card="user_card"/>
                    </div>
                    <div class="box_selected_m" v-if="selected_menu===4 && is_type_user===editor">
                        <div class="mobile_menu_1 pb_65" v-if="isMobile">
                            <div class="title_r">Reviews</div>
                            <div class="review_items">
                            <div class="review_item" v-for="review in editor_reviews">
                                <div class="header_box_review">
                                    <div>
                                        <img v-if="review.user && review.user.avatar && review.user.avatar.url" class="avatar review-avatar" :src="review.user.avatar.url" alt="avatar">
                                        <img v-else class="avatar review-avatar" src='@/assets/images/sbcf-default-avatar.png' alt="">
                                    </div>
                                    <div>
                                        <div v-if="review.image_job">
                                            Job<span> #{{review.image_job.id}}</span>
                                            <span v-if="review.image_job.name" class="ms-2">"{{review.image_job.name}}"</span>
                                        </div>
                                        <star-rating
                                            v-model="review.rating"
                                            :show-rating="false"
                                            v-bind:star-size="18"
                                            inactive-color="#D1D2D3"
                                            active-color="#494949"
                                            :read-only="true"/>
                                    </div>
                                </div>
                                <div class="d-flex gap-3 mt-4">
                                    <div v-if="review.message">
                                        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_3328_3146)">
                                                <path d="M6.40167 2.11288L5.85774 1.31543C2.09205 3.72771 0 6.65833 0 9.07061C0 11.4031 1.79916 12.4996 3.32636 12.4996C5.25105 12.4996 6.61088 10.9446 6.61088 9.30985C6.61088 7.93425 5.69038 6.75801 4.45607 6.31942C4.10042 6.1998 3.76569 6.10012 3.76569 5.52197C3.76569 4.78433 4.33054 3.68784 6.40167 2.11288ZM14.7071 2.11288L14.1632 1.31543C10.4393 3.72771 8.30544 6.65833 8.30544 9.07061C8.30544 11.4031 10.1464 12.4996 11.6736 12.4996C13.6192 12.4996 15 10.9446 15 9.30985C15 7.93425 14.0586 6.75801 12.7824 6.31942C12.4268 6.1998 12.113 6.10012 12.113 5.52197C12.113 4.78433 12.6987 3.68784 14.7071 2.11288Z" fill="#494949"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3328_3146">
                                                    <rect width="15" height="12.5" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <div class="review_message">{{ review.message }}</div>
                                    <div v-if="review.message">
                                        <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="rotated-180">
                                            <g clip-path="url(#clip0_3328_3146)">
                                                <path d="M6.40167 2.11288L5.85774 1.31543C2.09205 3.72771 0 6.65833 0 9.07061C0 11.4031 1.79916 12.4996 3.32636 12.4996C5.25105 12.4996 6.61088 10.9446 6.61088 9.30985C6.61088 7.93425 5.69038 6.75801 4.45607 6.31942C4.10042 6.1998 3.76569 6.10012 3.76569 5.52197C3.76569 4.78433 4.33054 3.68784 6.40167 2.11288ZM14.7071 2.11288L14.1632 1.31543C10.4393 3.72771 8.30544 6.65833 8.30544 9.07061C8.30544 11.4031 10.1464 12.4996 11.6736 12.4996C13.6192 12.4996 15 10.9446 15 9.30985C15 7.93425 14.0586 6.75801 12.7824 6.31942C12.4268 6.1998 12.113 6.10012 12.113 5.52197C12.113 4.78433 12.6987 3.68784 14.7071 2.11288Z" fill="#494949"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3328_3146">
                                                    <rect width="15" height="12.5" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
        <div class="modal" id="unlinkInstagramModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="input_modal">
                            Are you sure you want to unlink Instagram? StrobeArt will not be able to post to Instagram on your behalf.
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="unlinkInstagram()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="unlinkShopifyModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="input_modal">
                            Are you sure you want to unlink Shopify? StrobeArt will not be able to post to Shopify on your behalf.
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="unlinkShopify()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="unlinkAmazonModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="input_modal">
                            Are you sure you want to unlink Amazon? StrobeArt will not be able to post to Amazon on your behalf.
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="unlinkAmazon()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserName" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Name</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': !user.first_name}" type="text" class="form-control" v-model="user.first_name" placeholder="First Name">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                            <input :class="{ 'is-invalid': !user.last_name}" type="text" class="form-control mt-3" v-model="user.last_name" placeholder="Last Name">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUserName">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserBusiness" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="is_type_user===business">Business</h5>
                        <h5 class="modal-title" v-else>Level of Retouching</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input v-if="is_type_user===business" :class="{ 'is-invalid': !user.business_name}" type="text" class="form-control" v-model="user.business_name" placeholder="Business">
                            <select class="form-select" v-else v-model="user.business_name" >
                                <option value="" disabled>Select your level</option>
                                <option v-for="(item,index) in level_user" :value="item" v-bind:selected="index === 0">{{ item }}</option>
                            </select>
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('business_name')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserLocation" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Location</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': !user.location}" type="text" class="form-control" v-model="user.location" placeholder="Location">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('location')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserBio" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Bio</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <textarea :class="{ 'is-invalid': !user.bio}" v-model="user.bio" placeholder="Bio" class="form-control" id="exampleFormControlTextarea1" rows="3"/>
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('bio')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalCheckPassword" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirm the password</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': password_message}" type="password" class="form-control" v-model="password" placeholder="Enter your password">
                            <div class="invalid-feedback">
                                This is wrong password
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="checkPassword">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserEmail" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Email</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': !user.email}" type="text" class="form-control" v-model="user.email" placeholder="Enter your new email">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('email')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserPhone" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Phone</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': !user.phone}" type="text" class="form-control" v-model="user.phone" placeholder="Enter your new email">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('phone')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserEnglishLevel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">English Level</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <select class="form-select" v-model="user.english_level" aria-label="Default select example">
                                <option disabled :value="null">Select English level...</option>
                                <option v-for="level in english_levels" :value="level">{{level}}</option>
                            </select>
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('english_level')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserPassword" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Change Password</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': password_message}" type="password" class="form-control" v-model="user.password" placeholder="Enter your new password">
                            <input :class="{ 'is-invalid': password_message}" type="password" class="form-control mt-3" v-model="user.confirm_password" placeholder="Confirm password">
                            <div class="invalid-feedback">
                                {{password_message}}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('password')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserCoupon" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Apply Coupon</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input type="text" class="form-control" v-model="user.coupon" placeholder="Enter your coupon">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('coupon')">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import {errorMessage} from "../services/messages";
import {mapMutations} from "vuex";
import {Modal} from "bootstrap";
import TypeUserEnum from "../enums/TypeUserEnum";
import BuildingPayment from "../components/BuildingPayment";
import PaymentPlan from "../components/PaymentPlan";
import BuildingPaymentEditor from "../components/BuildingPaymentEditor";
import helpFunctions from "../helpFunctions";
import ('../css/custom.css');

export default {
    name: 'Settings',
    components: {
        MainLayout,
        BuildingPayment,
        BuildingPaymentEditor,
        PaymentPlan
    },
    data() {
        return {
            level_user: ['Beginner','Proficient','Expert'],
            english_levels: ['Conversational', 'Advanced', 'Fluent'],
            user: {},
            is_type_user: true,
            business: TypeUserEnum.BUSINESS,
            editor: TypeUserEnum.EDITOR,
            password: '',
            password_message: null,
            targetModal: '',
            editModals: {},

            unlinkInstagramModal:null,
            unlinkShopifyModal:null,
            unlinkAmazonModal:null,

            is_instagram: false,
            is_shopify: false,
            is_amazon: false,
            payment_history: {},
            selected_menu: 0,
            user_card: null,
            editor_reviews: [],
        }
    },
    destroyed() {
        if (this.unlinkShopifyModal) {
            this.unlinkShopifyModal.dispose();
        }
        if (this.unlinkAmazonModal) {
            this.unlinkAmazonModal.dispose();
        }
        if (this.unlinkInstagramModal) {
            this.unlinkInstagramModal.dispose();
        }
        Object.values(this.editModals).forEach(editModal => {
            editModal.dispose();
        });
    },
    async mounted() {
        this.editModals = helpFunctions.editModals;
        await this.getProfileUser();
        this.selected_menu = this.user.type_user==this.editor ? 3 : (this.isMobile ? 1 : 2);
        this.checkTabQuery();
        this.editModals.userName = new Modal(document.getElementById('editModalUserName'));
        this.editModals.userBusiness = new Modal(document.getElementById('editModalUserBusiness'));
        this.editModals.userLocation = new Modal(document.getElementById('editModalUserLocation'));
        this.editModals.userBio = new Modal(document.getElementById('editModalUserBio'));
        this.editModals.userEmail = new Modal(document.getElementById('editModalUserEmail'));
        this.editModals.userPhone = new Modal(document.getElementById('editModalUserPhone'));
        this.editModals.userEnglishLevel = new Modal(document.getElementById('editModalUserEnglishLevel'));
        this.editModals.userPassword = new Modal(document.getElementById('editModalUserPassword'));
        this.editModals.userCoupon = new Modal(document.getElementById('editModalUserCoupon'));
        this.editModals.checkPassword = new Modal(document.getElementById('modalCheckPassword'));
        this.unlinkInstagramModal = new Modal(document.getElementById('unlinkInstagramModal'));
        this.unlinkShopifyModal = new Modal(document.getElementById('unlinkShopifyModal'));
        this.unlinkAmazonModal = new Modal(document.getElementById('unlinkAmazonModal'));
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
            'setUpdateUserProfile'
        ]),
        async updateUserName() {
            if (!this.user.first_name || !this.user.last_name) {
                return;
            }
            try {
                this.showLoader();
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}profile/update-user-name`, {
                    'first_name': this.user.first_name,
                    'last_name': this.user.last_name
                });
                this.updateUserStorage(resp?.data?.data || null);
                this.editModals.userName.hide();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async updateUser(variable) {
            if(variable === 'password') {
                this.password_message = helpFunctions.password(this.user[variable]);
                if (this.password_message) {
                    return;
                }
                else if (!this.user.confirm_password || this.user.password !== this.user.confirm_password) {
                    this.password_message = 'Please make sure your passwords match';
                    return;
                }
            }
            else if (!this.user[variable]) {
                return;
            }
            let postdata = {};
            postdata[variable] = this.user[variable];
            try {
                this.showLoader();
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}profile/update-user-` + variable.replace("_", "-"), postdata);
                this.updateUserStorage(resp?.data?.data || null);
                Object.values(this.editModals).forEach(editModal => {
                    editModal.hide();
                });
                this.password = '';
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        openEditModal(name) {
            this.targetModal = this.editModals[name];
            this.editModals[name].show();
        },
        async getProfileUser() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/setting`);
                this.user = response?.data?.data?.user || {};
                this.user_card = response?.data?.data?.user?.user_card || {};
                this.is_type_user = response?.data?.data?.user?.type_user || false;
                this.editor_reviews = response?.data?.data?.user?.editor_reviews || {};
                this.payment_history = response?.data?.data?.user?.payment_history || {};
                this.is_instagram = helpFunctions.isEmptyObject(response?.data?.data?.user?.login_instagram || {});
                this.is_shopify = helpFunctions.isEmptyObject(response?.data?.data?.user?.shopify_connect || {});
                this.is_amazon = helpFunctions.isEmptyObject(response?.data?.data?.user?.aws || {});
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async checkPassword() {
            try {
                this.showLoader();
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}check-password`, { 'password': this.password });
                if (resp?.data?.data.check == true) {
                    this.editModals.checkPassword.hide();
                    this.password = '';
                    this.targetModal.show();
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        updateUserStorage(data) {
            if (data) {
                localStorage.setItem('strobeart_user', JSON.stringify(data));
                this.setUser(data || {});
                this.setUpdateUserProfile(true);
            }
        },
        async updateAvatar(e) {
            const tmpFiles = e.target.files;
            if (tmpFiles.length === 0) {
                return false;
            }
            try {
                this.showLoader();
                let data = new FormData();
                data.append('avatar', tmpFiles[0]);
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}profile/update-avatar`, data);
                this.updateUserStorage(resp?.data?.data || null);
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        editAvatar() {
            this.$refs.avatar_file.click();
        },
        async unlinkInstagram() {
           try {
               this.showLoader();
               await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-instagram`);
               this.is_instagram = false;
               this.unlinkInstagramModal.hide();
           } catch (e) {
               const message = e?.response?.data?.error?.message || 'ERROR';
               errorMessage(message);
           }
           this.hideLoader();
       },
        async unlinkShopify() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-shopify`);
                this.is_shopify = false;
                this.unlinkShopifyModal.hide();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async unlinkAmazon() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-amazon`);
                this.is_amazon = false;
                this.unlinkAmazonModal.hide();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        unlinkShopifyModalOpen() {
            this.unlinkShopifyModal.show();
        },
        unlinkAmazonModalOpen() {
            this.unlinkAmazonModal.show();
        },
        unlinkInstagramModalO() {
            this.unlinkInstagramModal.show();
        },
        logOut() {
            delete localStorage.strobeart_user;
            delete localStorage.strobeart_jwt;
            delete localStorage.logo;
            delete localStorage.defaultColor;
            delete localStorage.defaultFont;
            delete localStorage.instagramLink;
            delete localStorage.facebookLink;
            delete localStorage.tiktokLink;
            this.$router.push({to: '/login', name: "Login"}).then();
        },
        checkTabQuery() {
            let tab = this.$route.query.tab ?? null;
            if (tab == 'membership') {
                this.selected_menu = 2;
            }
            if (tab == 'billing') {
                this.selected_menu = 3;
            }
        },
        selectMenu(val) {
            this.selected_menu = val;
            if (val == 2) {
                this.getProfileUser();
            }
        },
    },
    computed: {
        avatar() {
            return this.user?.avatar?.url || null;
        },
        isMobile() {
            return window.innerWidth <= 992;
        },
    }
}
</script>

<style lang="scss" scoped>
.form-select:focus {
    border-color: #cccccc;
    box-shadow: none;
}
.text2 {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #494949;
}
.page_profile_setting {
    margin-top: 177px;
    margin-bottom: 230px;
    display: flex;
}
.menu_setting {
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.setting_box {
    border-left: 1px solid rgba(73, 73, 73, 0.5);
    padding: 20px 0 20px 85px
}
.menu_title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: rgba(73, 73, 73, 0.35);
}
.active_menu {
    color: #494949;
}
.box_avatar {
    position: relative;
    width: 136px;
    height: 136px;
}
.icon_edit {
    position: absolute;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
}
.avatar {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0.2px solid #494949;
}
.user_name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 44px;
    color: #494949;
}
.info_user {
    display: flex;
}
.info2 {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.bio_text {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: rgba(73, 73, 73, 0.35);
    position: relative;
    left: -10px;
}
.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    gap: 35px;
}
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}
.modal-title {
    width: 100%;
    text-align: center;
}
.input_modal input {
    width: 100%;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    padding: 7px 16px;
}
.form-control.is-invalid, .form-control:invalid {
    border-color: #dc3545 !important;
}
textarea:focus {
    border: 1px solid #ced4da;
}
.mobile_menu_1 {
    display: none;
}
.unlink_instagram {
    background: white;
    color: #AD967F;
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    width: 225px;
    cursor: pointer;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin-top: 30px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

    &.mob_v {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 0;
        width: 120px;
        height: 60px;
        padding: 15px;
        text-align: center;
        font-size: 15px;
    }
}
.box_selected_m {
    position: relative;
}
.mob_v {
    display: none;
}
.with_underline {
    border-bottom: 0.5px solid rgba(73, 73, 73, 0.45);
}
.with_border {
    border: 0.7px solid rgba(73, 73, 73, 0.45);
    border-radius: 4px;
}
.header_box_review {
    display: flex;
    align-items: center;
    gap: 28px;
}
.title_r {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: #494949;
}

@media only screen and (max-width: 992px) {
    .mob_v {
        display: flex;
    }
    .page_profile_setting {
        margin-top: 78px;
        margin-bottom: 0;
        flex-direction: column;
    }
    .menu_setting {
        display: flex;
        flex-direction: initial;
        gap: 0;
        width: 100%;
        border-bottom: 1.5px solid rgba(73, 73, 73, 0.25);
    }
    .menu_title {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        width: 33%;
        text-align: center;
    }
    .active_menu {
        border-bottom: 2.3px solid #494949;
        padding-bottom: 15px;
    }
    .box_avatar {
        width: 80px;
        height: 80px;
        margin-left: auto;
        margin-right: auto;
    }
    .setting_box {
        border-left: 0px solid rgba(73, 73, 73, 0.5);
        padding: 19px 27px;
        width: 100%;
    }
    .user_name {
        font-size: 18px;
        line-height: 22px;
    }
    .icon_edit {
        bottom: 2px;
        right: -6px;
        width: 20px;
        height: 20px;
    }
    .box_selected_m {
        flex-direction: column;
        height: 65vh;
    }
    .mobile_menu_1 {
        display: block;
    }
    .info_user {
        align-items: center;
        .text2 {
            font-size: 14px !important;
        }
    }
    .bio_text {
        border: 0px solid rgba(73, 73, 73, 0.45);
        box-sizing: border-box;
        border-radius: 4px;
        padding: 12px 0px;
        font-style: normal;

        position: relative;
        left: 0;


        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #494949;
    }
    .text2 {
        font-weight: 500;
        font-size: 12.5px;
        line-height: 20px;
    }
    .modal-footer {
        gap: 10px;
    }
}
</style>
